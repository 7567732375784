import React from 'react';
import cx from 'classnames';
let {Component, PropTypes} = React;


export class Spinner extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number
  }

  static defaultProps = {
    width: 50,
    height: 50
  }

  render() {
    return (
      <div className="spinner">
        <svg width={this.props.width} height={this.props.height} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" className="uil-default">
          <rect x="0" y="0" width="100" height="100" fill="none" className="bk"></rect>
          <rect  x='46.5' y='40' width='7' height='20' rx='0' ry='0' fill='#0087c7' transform='rotate(0 50 50) translate(0 -30)'>
            <animate attributeName='opacity' from='1' to='0' dur='1s' begin='0s' repeatCount='indefinite'/>
          </rect>
          <rect  x='46.5' y='40' width='7' height='20' rx='0' ry='0' fill='#0087c7' transform='rotate(30 50 50) translate(0 -30)'>
            <animate attributeName='opacity' from='1' to='0' dur='1s' begin='0.08333333333333333s' repeatCount='indefinite'/>
          </rect>
          <rect  x='46.5' y='40' width='7' height='20' rx='0' ry='0' fill='#0087c7' transform='rotate(60 50 50) translate(0 -30)'>
            <animate attributeName='opacity' from='1' to='0' dur='1s' begin='0.16666666666666666s' repeatCount='indefinite'/>
          </rect>
          <rect  x='46.5' y='40' width='7' height='20' rx='0' ry='0' fill='#0087c7' transform='rotate(90 50 50) translate(0 -30)'>
            <animate attributeName='opacity' from='1' to='0' dur='1s' begin='0.25s' repeatCount='indefinite'/>
          </rect>
          <rect  x='46.5' y='40' width='7' height='20' rx='0' ry='0' fill='#0087c7' transform='rotate(120 50 50) translate(0 -30)'>
            <animate attributeName='opacity' from='1' to='0' dur='1s' begin='0.3333333333333333s' repeatCount='indefinite'/>
          </rect>
          <rect  x='46.5' y='40' width='7' height='20' rx='0' ry='0' fill='#0087c7' transform='rotate(150 50 50) translate(0 -30)'>
            <animate attributeName='opacity' from='1' to='0' dur='1s' begin='0.4166666666666667s' repeatCount='indefinite'/>
          </rect>
          <rect  x='46.5' y='40' width='7' height='20' rx='0' ry='0' fill='#0087c7' transform='rotate(180 50 50) translate(0 -30)'>
            <animate attributeName='opacity' from='1' to='0' dur='1s' begin='0.5s' repeatCount='indefinite'/>
          </rect>
          <rect  x='46.5' y='40' width='7' height='20' rx='0' ry='0' fill='#0087c7' transform='rotate(210 50 50) translate(0 -30)'>
            <animate attributeName='opacity' from='1' to='0' dur='1s' begin='0.5833333333333334s' repeatCount='indefinite'/>
          </rect>
          <rect  x='46.5' y='40' width='7' height='20' rx='0' ry='0' fill='#0087c7' transform='rotate(240 50 50) translate(0 -30)'>
            <animate attributeName='opacity' from='1' to='0' dur='1s' begin='0.6666666666666666s' repeatCount='indefinite'/>
          </rect>
          <rect  x='46.5' y='40' width='7' height='20' rx='0' ry='0' fill='#0087c7' transform='rotate(270 50 50) translate(0 -30)'>
            <animate attributeName='opacity' from='1' to='0' dur='1s' begin='0.75s' repeatCount='indefinite'/>
          </rect>
          <rect  x='46.5' y='40' width='7' height='20' rx='0' ry='0' fill='#0087c7' transform='rotate(300 50 50) translate(0 -30)'>
            <animate attributeName='opacity' from='1' to='0' dur='1s' begin='0.8333333333333334s' repeatCount='indefinite'/>
          </rect>
          <rect  x='46.5' y='40' width='7' height='20' rx='0' ry='0' fill='#0087c7' transform='rotate(330 50 50) translate(0 -30)'>
            <animate attributeName='opacity' from='1' to='0' dur='1s' begin='0.9166666666666666s' repeatCount='indefinite'/>
          </rect>
        </svg>
      </div>
    );
  }
}


export class Loading extends Component {
  static propTypes = {
    show: PropTypes.bool
  }

  static defaultProps = {
    show: false
  }

  render() {
    return (
      <div className={cx('loading-container', {hide: !this.props.show})}>
        <div className="loading-indicator">
          <Spinner />
        </div>
      </div>
    );
  }
}


export class DropDownIcon extends Component {
  render() {
    return (
      <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24">
          <path d="M7 10l5 5 5-5z"/>
          <path d="M0 0h24v24H0z" fill="none"/>
      </svg>
    );
  }
}