import React from 'react';
import cx from 'classnames';
let {Component, PropTypes} = React;


export class Header extends Component {
  render() {
    return (
      <div {...this.props} className={cx('table-header', this.props.className)}>
        {this.props.children}
      </div>
    );
  }
};


export class Body extends Component {
  render() {
    return (
      <div {...this.props} className={cx('table-body', this.props.className)}>
        {this.props.children}
      </div>
    );
  }
};


export class Row extends Component {
  render() {
    return (
      <div {...this.props} className={cx('table-row', this.props.className)}>
        {this.props.children}
      </div>
    );
  }
};


export class Cell extends Component {
  render() {
    return (
      <div {...this.props} className={cx('table-cell', this.props.className)}>
        {this.props.children}
      </div>
    );
  }
};


export class Table extends Component {
  render() {
    return (
      <div className={cx('table', this.props.className)}>
        {this.props.children}
      </div>
    );
  }
};
