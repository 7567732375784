import {EventEmitter} from 'events';
import assign from 'object-assign';
import dispatcher from '../dispatcher';
import {TripConstants as C} from '../constants';
import {getUrl} from '../util';
import $ from 'jquery';


const CHANGE_EVENT = 'change';
let tripsById = {};


const TripStore = assign(new EventEmitter(), {
  emitChange() {
    this.emit(CHANGE_EVENT);
  },

  addChangeListener(fn) {
    this.addListener(CHANGE_EVENT, fn);
  },

  removeChangeListener(fn) {
    this.removeListener(CHANGE_EVENT, fn);
  },

  get(id) {
    return tripsById[id] || null;
  },

  dispatchToken: dispatcher.register(action => {
    switch (action.type) {
      case C.UPDATE:
        tripsById[action.trip.Pledge_Campaign_ID] = action.trip;
        TripStore.emitChange();
        break;
    }

    return true;
  })
});


export default TripStore;