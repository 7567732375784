export const SearchConstants = {
  FETCH_ALL: 'SEARCH_FETCH_ALL',
  FILTER: 'SEARCH_FILTER'
};


export const TripConstants = {
  FETCH: 'TRIPS_FETCH_ONE',
  UPDATE: 'TRIPS_UPDATE'
};


export const ParticipantConstants = {
  FETCH_TRIP: 'PARTICIPANTS_FETCH_TRIP',
  FETCH: 'PARTICIPANTS_FETCH_ONE',
  UPDATE_TRIP: 'PARTICIPANTS_UPDATE_TRIP',
  UPDATE: 'PARTICIPANTS_UPDATE',
  SAVE: 'PARTICIPANTS_SAVE'
};
