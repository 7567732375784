import React from 'react';
import ReactDOM from 'react-dom';
import MissionTripsApp from './components/MissionTripsApp';
import $ from 'jquery';
import URL from 'url';
import {setToken, setupUrl} from './util';


let script = $("script:last")[0];
let scriptSrc = script.src;


export function init(el, token = null) {
  setToken(token);
  let url = URL.parse(window.__MISSION_TRIPS_APP_DOMAIN || scriptSrc, true);
  setupUrl({
    host: url.host,
    hostname: url.hostname,
    port: url.port,
    protocol: url.protocol
  });
  if (typeof el === 'string') {
    el = document.querySelector(el);
  }
  ReactDOM.render(<MissionTripsApp />, el);
}