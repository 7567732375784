import React from 'react';
import {getUrl} from '../util';
import $ from 'jquery';
let {Component, PropTypes} = React;

// Components
import ParticipantPhoto from './ParticipantPhoto';
import {Loading} from 'bp/ui';


export default class ParticipantPhotoEditor extends Component {
  static propTypes = {
    participant: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);
    
    this.state = {
      revision: 0,
      loading: false
    };
  }

  render() {
    return (
      <div className="participant-photo-editor">
        <Loading show={this.state.loading} />
        <ParticipantPhoto url={this.props.participant.File_URL} revision={this.state.revision} />
        <input type="file" className="hide" ref="input" onChange={this._onFileChange} accept="image/*" />
        <a className="button edit" href="javascript:void(0)" onClick={this._onEditClick}>Edit</a>
      </div>
    );
  }

  _onEditClick = (event) => {
    event.preventDefault();
    this.refs.input.click();
  }

  _onFileChange = (event) => {
    let files = event.target.files;

    if (files && files.length && window.FormData) {
      this._uploadFile(files[0]);
    }
  }

  _uploadFile(file) {
    this.setState({
      loading: true
    });

    let participant = this.props.participant;
    let url = getUrl(`/api/v1/trips/${participant.Pledge_Campaign_ID}/participants/${participant.Pledge_ID}/photo`);
    let data = new FormData();
    data.append('file', file);

    $.ajax({
      type: 'POST',
      url: url,
      processData: false,
      contentType: false,
      cache: false,
      data: data
    }).done(response => {
      this.setState({
        loading: false,
        revision: this.state.revision + 1
      });
      window.location.reload(false);
    });
  }
}
