import React from 'react';
import cx from 'classnames';
import {v4 as uuid} from 'node-uuid';
import {validate} from './validation';
import $ from 'jquery';
let {Component, PropTypes} = React;

import {Loading} from './ui';


export const ParentMixin = {
  register(field) {
    this.fields.push(field);
  },

  deregister(field) {
    let search = this.fields.filter(i => i.props.name == field.props.name);
    search.forEach(i => {
      let index = this.fields.indexOf(i);
      if (index !== -1) {
        this.fields.splice(index, 1);
      }
    });
  },

  getData(data = {}) {
    this.fields.forEach(i => {
      i.getData(data);
    });
    return data;
  },

  getFieldsByName() {
    let fields = {};
    this.fields.forEach(i => {
      fields[i.props.name] = i;
    });
    return fields;
  },

  setData(data) {
    let fieldsByName = this.getFieldsByName();
    for (let i in data) {
      if (data.hasOwnProperty(i) && fieldsByName.hasOwnProperty(i)) {
        fieldsByName[i].setData(data[i]);
      }
    }
  },

  reset() {
    this.fields.forEach(i => {
      i.reset();
    });
  },

  clearErrors() {
    let fieldsByName = this.getFieldsByName();
    for (let i in fieldsByName) {
      if (fieldsByName.hasOwnProperty(i)) {
        fieldsByName[i].clearErrors();
      }
    }
  },

  setErrors(errors={}) {
    let fieldsByName = this.getFieldsByName();
    for (let i in errors) {
      if (errors.hasOwnProperty(i) && fieldsByName.hasOwnProperty(i)) {
        fieldsByName[i].setErrors(errors[i]);
      }
    }
  }
};


export const ChildMixin = {
  componentDidMount() {
    if (this.props.register) {
      this.props.register(this);
    }
  },

  componentWillUnmount() {
    if (this.props.deregister) {
      this.props.deregister(this);
    }
  }
};


export function applyParentMixin(component) {
  component.fields = [];
  component.register = ParentMixin.register.bind(component);
  component.deregister = ParentMixin.deregister.bind(component);

  if (!component.getData) {
    component.getData = ParentMixin.getData.bind(component);
  }

  if (!component.getFieldsByName) {
    component.getFieldsByName = ParentMixin.getFieldsByName.bind(component);
  }

  if (!component.setData) {
    component.setData = ParentMixin.setData.bind(component);
  }

  if (!component.clearErrors) {
    component.clearErrors = ParentMixin.clearErrors.bind(component);
  }

  if (!component.setErrors) {
    component.setErrors = ParentMixin.setErrors.bind(component);
  }

  if (!component.reset) {
    component.reset = ParentMixin.reset.bind(component);
  }
}


export function applyChildMixin(component) {
  component.componentDidMount = ChildMixin.componentDidMount.bind(component);
  component.componentWillUnmount = ChildMixin.componentWillUnmount.bind(component);
}


export class Form extends Component {
  static propTypes = {
    submitted: PropTypes.bool,
    onChange: PropTypes.func
  }

  static defaultProps = {
    onChange: x => x
  }

  constructor(props) {
    super(props);

    this.fields = [];
    this.register = ParentMixin.register.bind(this);
    this.deregister = ParentMixin.deregister.bind(this);
    this.getData = ParentMixin.getData.bind(this);
    this.getFieldsByName = ParentMixin.getFieldsByName.bind(this);
    this.setData = ParentMixin.setData.bind(this);
    this.clearErrors = ParentMixin.clearErrors.bind(this);
    this.reset = ParentMixin.reset.bind(this);
  }

  render() {
    let children = React.Children.map(
      this.props.children,
      i => i && typeof i.type === 'function' ? React.cloneElement(i, {
        form: this, register: this.register, deregister: this.deregister,
        onChange: this._onChange, submitted: this.props.submitted
      }) : i
    );

    return (
      <div>
        {children}
      </div>
    );
  }

  isValid() {
    for (let i = 0; i < this.fields.length; i++) {
      if (!this.fields[i].isValid()) {
        return false;
      }
    }
    return true;
  }

  setErrors(errors = {}) {
    let setErrors = ParentMixin.setErrors.bind(this);
    setErrors(errors);
    for (let i = 0; i < this.fields.length; i++) {
      if (!this.fields[i].props.name) {
        this.fields[i].setErrors(errors);
      }
    }
  }

  _onChange = () => {
    this.props.onChange();
  }
}


export class Field extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    validators: PropTypes.arrayOf(PropTypes.func),
    form: PropTypes.object,
    deregister: PropTypes.func,
    register: PropTypes.func,
    onChange: PropTypes.func,
    submitted: PropTypes.bool,
    show: PropTypes.bool,
    defaultValue: PropTypes.any,
    className: PropTypes.any
  }

  static defaultProps = {
    validators: [],
    defaultValue: undefined
  }

  constructor(props) {
    super(props);

    applyChildMixin(this);

    this.state = {
      value: props.defaultValue,
      errors: validate(props.defaultValue, props.validators)
    };
  }

  reset() {
    this.setState({
      value: this.props.defaultValue,
      errors: validate(this.props.defaultValue, this.props.validators)
    });
  }

  render() {
    let input = React.cloneElement(this.props.children, {
      form: this.props.form,
      value: this.state.value,
      onChange: this._onChange
    });
    let errorsClasses = cx('errors', {
      'hide': !this.props.submitted || this.isValid()
    });

    return (
      <div className={cx('field', {hide: this.props.show === false}, this.props.className)}>
        <label>
          {this.props.label}
          <span className="required-astrisk">*</span>
        </label>
        <div className="input">
          {input}
        </div>
        <div className={errorsClasses}>
          {this.state.errors.map(i =>
            <div className="error" key={i}>{i}</div>
          )}
        </div>
      </div>
    );
  }

  setErrors(errors=[]) {
    this.setState({
      errors: errors
    });
  }

  isValid() {
    return this.state.errors.length === 0;
  }

  getData(data = {}) {
    data[this.props.name] = this.state.value;
    return data;
  }

  setData(value) {
    this.setState({
      errors: validate(value, this.props.validators),
      value: value
    });
  }

  clearErrors() {
    this.setState({
      errors: []
    });
  }

  _onChange = (value) => {
    this.setState({
      value: value,
      errors: validate(value, this.props.validators)
    }, this.props.onChange);
  }
}


export class Input extends Component {
  static propTypes = {
    form: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func
  }

  static defaultProps = {
    value: null
  }
}


export class TextInput extends Input {
  render() {
    return (
      <input type="text" value={this.props.value || ''} onChange={this._onChange} />
    );
  }

  _onChange = (event) => {
    this.props.onChange(event.target.value || null);
  }
}


export class PasswordInput extends TextInput {
  render() {
    return (
      <input type="password" value={this.props.value || ''} onChange={this._onChange} />
    );
  };
}


export class PhoneInput extends TextInput {
  render() {
    return (
      <input type="tel" value={this.props.value || ''} onChange={this._onChange} />
    );
  };
}


export class EmailInput extends TextInput {
  render() {
    return (
      <input type="email" value={this.props.value || ''} onChange={this._onChange} />
    );
  };
}


export class LongTextInput extends TextInput {
  render() {
    return (
      <textarea value={this.props.value || ''} onChange={this._onChange} />
    );
  }
}


export class DropDownInput extends Input {
  static propTypes = {
    form: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
    parser: PropTypes.func,
    formatter: PropTypes.func
  }

  static defaultProps = {
    parser: x => x,
    formatter: x => x || ''
  }

  render() {
    return (
      <select onChange={this._onChange} value={this.props.formatter(this.props.value)}>
        {this.props.children}
      </select>
    );
  }

  _onChange = (event) => {
    this.props.onChange(this.props.parser(event.target.value));
  }
}


export class Option extends Component {
  static propTypes = {
    value: PropTypes.any,
    children: PropTypes.string
  }

  componentDidMount() {
    if (this.props.register) {
      this.props.register(this);
    }
  }

  componentWillUnmount() {
    if (this.props.deregister) {
      this.props.deregister(this);
    }
  }

  render() {
    return (
      <option value={this.props.value}>{this.props.children}</option>
    );
  }
}


export class Checkbox extends Input {
  static propTypes = {
    form: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
    children: PropTypes.string
  }

  static defaultProps = {
    value: false
  }

  constructor(props) {
    super(props);
    this.uuid = uuid();
  }

  render() {
    return (
      <div className="checkbox-container">
        <input type="checkbox" checked={this.props.value || false}
            onChange={this._onChange} id={this.uuid} />
        <label htmlFor={this.uuid}>{this.props.children}</label>
      </div>
    );
  }

  _onChange = (event) => {
    this.props.onChange(event.target.checked);
  }
}


export class FieldGroup extends Component {
  static propTypes = {
    show: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    form: PropTypes.object,
    deregister: PropTypes.func,
    register: PropTypes.func,
    onChange: PropTypes.func,
    submitted: PropTypes.bool,
    forceValue: PropTypes.bool
  }

  static defaultProps = {
    show: true
  }

  constructor(props) {
    super(props);

    applyParentMixin(this);
    applyChildMixin(this);

    this.state = {
      errors: []
    };
  }

  isValid() {
    return this.props.show ?
      this.fields.map(i => i.isValid()).filter(i => !i).length === 0 && this.state.errors.length === 0 :
      true;
  }

  setErrors(errors = {}) {
    let setErrors = ParentMixin.setErrors.bind(this);
    if (Array.isArray(errors)) {
      this.setState({
        errors: errors
      });
    }
    else {
      setErrors(errors);
    }
  }

  clearErrors() {
    let clearErrors = ParentMixin.clearErrors.bind(this);
    this.setState({
      errors: []
    });
    clearErrors();
  }

  getData(data = {}) {
    let getData = ParentMixin.getData.bind(this);
    if (this.props.show || this.props.forceValue) {
      if (!this.props.name) {
        getData(data);
      }
      else {
        data[this.props.name] = {};
        getData(data[this.props.name]);
      }
    }
    return data;
  }

  render() {
    let children = React.Children.map(
      this.props.children,
      i => i && typeof i.type === 'function' ? React.cloneElement(i, {
        form: this, register: this.register, deregister: this.deregister,
        onChange: this._onChange, submitted: this.props.submitted
      }) : i
    );
    let errorsClasses = cx('errors', {
      'hide': !this.props.submitted || this.isValid()
    });

    return (
      <div className={cx('field-group', {hide: !this.props.show})}>
        <label className={cx({hide: !this.props.label})}>{this.props.label}</label>
        <div className={errorsClasses}>
          {this.state.errors.map((i, n) =>
            <div className="error" key={n}>{i}</div>
          )}
        </div>
        <div className="fields">
          {children}
        </div>
      </div>
    );
  }

  _onChange = () => {
    this.props.onChange();
  }
}


export class AjaxDropDownInput extends Input {
  static propTypes = {
    form: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
    url: PropTypes.string.isRequired,
    makeLabel: PropTypes.func.isRequired,
    makeValue: PropTypes.func
  }

  static defaultProps = {
    makeValue: item => item.id
  }

  constructor(props) {
    super(props);

    this.state = {
      items: []
    };
  }

  componentDidMount() {
    $.get(this.props.url).done(response => {
      this.setState({
        items: response.data
      });
    });
  }

  render() {
    return (
      <select onChange={this._onChange} value={this.props.value}>
        {this.state.items.map(this._makeOption)}
      </select>
    );
  }

  _makeOption = (item, n) => {
    let value = JSON.stringify(this.props.makeValue(item));
    let label = this.props.makeLabel(item);
    return (
      <option key={n} value={value}>{label}</option>
    );
  }

  _onChange = (event) => {
    let value = JSON.parse(event.target.value);
    this.props.onChange(value);
  }
}


export class AjaxForm extends Component {
  static propTypes = {
    get: PropTypes.string,
    post: PropTypes.string,
    put: PropTypes.string,
    data: PropTypes.object,
    onSuccess: PropTypes.func,
    onError: PropTypes.func
  }

  constructor(props) {
    super(props);

    if (props.post && props.put) {
      throw new Error("Choose one: post or put. Not both.");
    }

    this.state = {
      loading: false,
      submitted: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.get !== this.props.get) {
      this._fetchData();
    }
    if (prevProps.data !== this.props.data) {
      this.refs.form.setData(this.props.data);
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.refs.form.setData(this.props.data);
    }

    this._fetchData();
  }

  setData(data) {
    this.refs.form.setData(data);
  }

  getData() {
    return this.refs.form.getData();
  }

  reset() {
    this.setState({
      submitted: false,
      loading: false
    });
    this.refs.form.reset();
  }

  render() {
    return (
      <form className="ajax-form" onSubmit={this._onSubmit} noValidate>
        <Form ref="form" submitted={this.state.submitted}>
          {this.props.children}
          <Loading show={this.state.loading} />
        </Form>
      </form>
    );
  }

  _fetchData = () => {
    if (this.props.get) {
      this.setState({
        loading: !this.props.data
      });

      $.get(this.props.get)
        .done(data => {
          this.setState({
            loading: false
          });
          this.refs.form.setData(data);
        });
    }
  }

  _onSubmit = (event) => {
    event.preventDefault();

    this.setState({
      submitted: true,
      loading: this.refs.form.isValid()
    }, () => {
      if (this.refs.form.isValid() && (this.props.post || this.props.put)) {
        $.ajax({
          type: this.props.post ? 'POST' : 'PUT',
          contentType: 'application/json',
          url: this.props.post || this.props.put,
          data: JSON.stringify(this.refs.form.getData())
        }).done(data => {
          this.setState({
            loading: false
          });
          this.refs.form.setData(data);
          if (this.props.onSuccess) {
            this.props.onSuccess(data);
          }
        }).fail(xhr => {
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.setState({
              loading: false
            });
            this.refs.form.setErrors(xhr.responseJSON.errors);
            if (this.props.onError) {
              this.props.onError(xhr.responseJSON.errors);
            }
          }
        });
      }
    });
  }
}
