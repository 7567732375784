import assign from 'object-assign';
import moment from 'moment';


export function validate(value, validators) {
  return validators.map(i => i(value) || "Invalid input.").filter(i => i !== true);
}


var emailPattern = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.([a-z]+)|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
var phonePattern = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
var datePattern = /^[\d]{2}\/[\d]{2}\/[\d]{4}$/;
var zipPattern = /^\d{5}(?:[-\s]\d{4})?$/;
var creditCardPattern = /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/;
var creditCardExpirationPattern = /^(\d{2})\/(\d{4})$/;
var cvvPattern = /^\d{3,4}$/;


export const validators = {
  required(message) {
    return function(value) {
      return (value || value === 0) ? true : message || "This field is required.";
    };
  },

  max(length, message) {
    return function(value) {
      return value ? (value.length <= length) || (message || "Too long. Max length: " + length) : true;
    };
  },

  requiredIf(condition, message) {
    return function(value) {
      var finalMessage = message || "This field is required.";
      if (condition()) {
        return value ? true : finalMessage;
      }
      else {
        return true;
      }
    }
  },

  email(message) {
    return function(value) {
      return value ? emailPattern.test(value) || (message || "Invalid email address.") : true;
    };
  },

  phone(message) {
    return function(value) {
      return value ? phonePattern.test(value) || (message || "Invalid phone number.") : true;
    };
  },

  date(message) {
    return function(value) {
      if (value) {
        return (datePattern.test(value) && moment(value, 'MM/DD/YYYY').isValid()) || (message || "Invalid date. Please use the format MM/DD/YYYY");
      }
      return true;
    }
  },

  zip(message) {
    return function(value) {
      return value ? zipPattern.test(value) || (message || "Please enter a valid US zip code.") : true;
    }
  },

  number(options, message) {
    options = assign({
      positive: false
    }, options || {});

    return function(value) {
      let finalMessage = message || 'Invalid number.';

      if (!value) {
        return true;
      }

      if (typeof value !== 'number') {
        return finalMessage;
      }

      if (options.positive && !(value > 0)) {
        return "Must be a positive value.";
      }

      return true;
    };
  },

  numberString(options, message) {
    options = assign({
      minLength: null,
      maxLength: null
    }, options || {});

    return function(value) {
      let finalMessage = message || 'Invalid input.';

      if (!value) {
        return true;
      }

      if (isNaN(Number(value))) {
        return finalMessage;
      }
      else if (options.minLength !== null && value.length < options.minLength) {
        return finalMessage;
      }
      else if (options.maxLength !== null && value.length > options.maxLength) {
        return finalMessage;
      }

      return true;
    };
  },

  oneOf(options, message) {
    return function(value) {
      if (!value) {
        return true;
      }

      if (options.indexOf(value) !== -1) {
        return true;
      }

      return message || "Invalid selection.";
    };
  },

  creditCard(message) {
    return function(value) {
      return value ? creditCardPattern.test(value) || (message || "Invalid credit card number.") : true;
    };
  },

  creditCardExpiration(invalidDateMessage, pastDateMessage) {
    return function(value) {
      if (!value) {
        return true;
      }
      var match = creditCardExpirationPattern.exec(value);
      if (match) {
        let month = Number(match[1]) - 1;
        let year = Number(match[2]);
        let m = moment({year: year, month: month});
        if (m.isValid()) {
          if (m.isBefore(moment(), 'month')) {
            return pastDateMessage || "Expiration date must not be in the past.";
          }
          return true;
        }
        return invalidDateMessage || "Invalid expiration date. Please use the format MM/YYYY.";
      }
      return invalidDateMessage || "Invalid expiration date. Please use the format MM/YYYY.";
    };
  },

  cvv(message) {
    return function(value) {
      if (!value) {
        return true;
      }
      return cvvPattern.test(value) || (message || "Invalid CVV number.");
    };
  }
};