import React from 'react';
import cx from 'classnames';
let {Component, PropTypes} = React;


export default class Progress extends Component {
  static propTypes = {
    percentage: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props);
  }

  render() {
    let percentage = Math.min(100, Math.round(this.props.percentage * 100)) || 0;
    let style = {
      width: 300 * this.props.percentage
    };

    return (
      <div className="donation-progress">
        <div className="donation-progress-bar" style={style} />
        <div className={cx('donation-max-marker', {surpassed: this.props.percentage > 0.95})} />
        <div className="donation-progress-text">
          {percentage}%
        </div>
      </div>
    );
  }
}