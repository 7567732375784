import dispatcher from '../dispatcher';
import {SearchConstants as C} from '../constants';


export default {
  fetchAll() {
    dispatcher.dispatch({
      type: C.FETCH_ALL
    });
  },

  filter(query) {
    dispatcher.dispatch({
      type: C.FILTER,
      filter: query
    });
  }
};