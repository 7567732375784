import React from 'react';
import moment from 'moment';
import {Table, Row, Cell, Body, Header} from 'bp/table';
import SearchStore from '../stores/SearchStore';
import SearchActions from '../actions/SearchActions';
import {hashHistory} from 'react-router';
let {Component, PropTypes} = React;


class SearchResult extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired
  }

  render() {
    let d = this.props.data;
    let startDate = moment(d.Trip_Start_Date);
    let endDate = moment(d.Trip_End_Date);

    return (
      <section onClick={this._onClick}>
        <a href="javascript:;">
          <div className="photo-wrapper">
            {d.image}
          </div>
          <h1>{d.display}</h1>
          <div className="type">{d.type_display}</div>
          <div className="dates">{startDate.format('MMMM D')} - {endDate.format('MMMM D, YYYY')}</div>
        </a>
      </section>
    );
  }

  _onClick = (event) => {
    event.preventDefault();
    let d = this.props.data;
    switch (d.type) {
      case "participant":
        hashHistory.push(`/trips/${d.Trip_ID}/participants/${d.Participant_ID}`);
        break;
      case "trip":
        hashHistory.push(`/trips/${d.Trip_ID}`);
        break;
    }
  }
}


class NoneFound extends Component {
  static propTypes = {
    query: PropTypes.string
  };

  render() {
    return (
      <div className="none-found">
        <span>
          No Mission Trip or Participant matches for '{this.props.query || ""}' were found.
        </span>
      </div>
    );
  }
}


export default class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: SearchStore.getAll(),
      query: SearchStore.getQuery()
    };
  }

  componentDidMount() {
    SearchStore.addChangeListener(this._onChange);
    SearchActions.fetchAll();
  }

  componentWillUnmount() {
    SearchStore.removeChangeListener(this._onChange);
  }

  render() {
    let noneFound = null;
    let query = this.state.query;

    if (query && !this.state.results.length) {
      noneFound = <NoneFound query={query} />
    }

    return (
      <div className="page search">
        <h2>Seacoast Mission Trips</h2>
        <p>
          Seacoast is dedicated to making a difference in the world. We invite you
          to join us in bringing hope to our global partners by serving on a global
          mission trip.
        </p>
        <p>
          Simply “click” on a trip below to see details, apply online, and/or
          support a team member. 
        </p>
        <p>
          Additional trips are added regularly, and dates and cost are subject to change.
        </p>
        <p>
          ***Due to COVID 19, we are continuing to monitor each country’s pandemic status,
          travel rules, and recommendations. Trip dates listed may be postponed as needed
          to ensure the safety of our teams and partners.
        </p>
        <div className="query-input-container">
          <input type="text" onChange={this._onQueryChange}
              defaultValue={SearchStore.getQuery() || ''}
              placeholder="Search by Participant Name or Destination" />
        </div>
        <section className="feed missions">
          {noneFound}
          {this.state.results.map((i, n) =>
            <SearchResult key={n} data={i} />
          )}
        </section>
      </div>
    );
  }

  _onQueryChange = (event) => {
    SearchActions.filter(event.target.value);
  }

  _onChange = () => {
    this.setState({
      results: SearchStore.getAll(),
      query: SearchStore.getQuery()
    });
  }
}