import React from 'react';
import {getUrl} from '../util';
let {Component, PropTypes} = React;


export default class ParticipantPhoto extends Component {
  static propTypes = {
    url: PropTypes.string,
    revision: PropTypes.number
  }

  render() {
    let revision = this.props.revision ? `?v=${this.props.revision}` : '';
    let url = this.props.url;
    let style = {
      backgroundImage: url ? `url(${url}${revision})` : undefined
    };

    return (
      <div className="participant-photo">
        <div className="participant-photo-placeholder" />
        <div className="participant-photo-inner" style={style} />
      </div>
    );
  }
}
