import React from 'react';
import {Router, Route, IndexRoute, hashHistory} from 'react-router';
let {Component, PropTypes} = React;

// import routes
import Search from './Search';
import NotFound from './NotFound';
import Trip from './Trip';
import Participant from './Participant';


export default class MissionTripsApp extends Component {
  render() {
    return (
      <div className="mission-trips-app" onKeyPress={this._onKeyPress}>
        <Router onUpdate={() => window.scrollTo(0, 0)} history={hashHistory}>
          <Route path="/" component={Search} />
          <Route path="/trips/:tripId" component={Trip} />
          <Route path="/trips/:tripId/participants/:participantId" component={Participant} />
          <Route path="*" component={NotFound} />
        </Router>
      </div>
    );
  }

  _onKeyPress = (event) => {
    if (event.which === 13) {
      event.preventDefault();
    }
    event.stopPropagation();
  }
}