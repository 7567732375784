import {EventEmitter} from 'events';
import assign from 'object-assign';
import dispatcher from '../dispatcher';
import {ParticipantConstants as C} from '../constants';
import {getUrl} from '../util';
import $ from 'jquery';


const CHANGE_EVENT = 'change';
let participantsById = {};
let participantsByTripId = {};


function fetch(tripId, id) {
  $.get(getUrl(`/api/v1/trips/${tripId}/participants/${id}`)).done(participant => {
    dispatcher.dispatch({
      type: C.UPDATE,
      participant: participant
    });
  });
}


function fetchTrip(tripId) {
  $.get(getUrl(`/api/v1/trips/${tripId}/participants`)).done(response => {
    dispatcher.dispatch({
      type: C.UPDATE_TRIP,
      tripId: tripId,
      participants: response.data
    });
  });
}


function save(tripId, id, data) {
  $.ajax({
    url: getUrl(`/api/v1/trips/${tripId}/participants/${id}`),
    type: 'POST',
    contentType: 'application/json',
    data: JSON.stringify(data)
  }).done(participant => {
    dispatcher.dispatch({
      type: C.UPDATE,
      participant: participant
    });
  });
}


const ParticipantStore = assign(new EventEmitter(), {
  emitChange() {
    this.emit(CHANGE_EVENT);
  },

  addChangeListener(fn) {
    this.addListener(CHANGE_EVENT, fn);
  },

  removeChangeListener(fn) {
    this.removeListener(CHANGE_EVENT, fn);
  },

  get(id) {
    return participantsById[id] || null;
  },

  getTrip(id) {
    return participantsByTripId[id] || [];
  },

  dispatchToken: dispatcher.register(action => {
    switch (action.type) {
      case C.FETCH:
        fetch(action.tripId, action.id);
        break;
      case C.UPDATE:
        participantsById[action.participant.Pledge_ID] = action.participant;
        let temp = participantsByTripId[action.participant.Pledge_Campaign_ID];
        if (temp) {
          let original = ParticipantStore.get(action.participant.Pledge_ID);
          if (original) {
            let index = temp.indexOf(original);
            if (index !== -1) {
              participantsByTripId[action.participant.Pledge_Campaign_ID] = [
                ...temp.slice(0, index),
                action.participant,
                ...temp.slice(index + 1)
              ];
            }
          }
        }
        ParticipantStore.emitChange();
        break;
      case C.FETCH_TRIP:
        fetchTrip(action.tripId);
        break;
      case C.UPDATE_TRIP:
        participantsByTripId[action.tripId] = action.participants;
        action.participants.forEach(i => {
          participantsById[i.Pledge_ID] = i;
        });
        ParticipantStore.emitChange();
        break;
      case C.SAVE:
        save(action.tripId, action.id, action.data);
        break;
    }

    return true;
  })
});


export default ParticipantStore;