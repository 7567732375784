import React from 'react';
import {EventEmitter} from 'events';
import assign from 'object-assign';
import $ from 'jquery';
import dispatcher from '../dispatcher';
import {SearchConstants as C} from '../constants';
import lunr from 'lunr';
import {getUrl} from '../util';
import ParticipantPhoto from '../components/ParticipantPhoto';
import TripPhoto from '../components/TripPhoto';


const CHANGE_EVENT = 'change';
let allResults = [];
let allResultsById = {};
let results = [];
let index = makeIndex();
let query = null;


function makeIndex() {
  return lunr(function() {
    this.field('title', {boost: 10});
    this.field('keywords', {boost: 5});
    this.field('status');
    this.field('type');
    this.field('type_display');
    this.ref('id');
  });
}


function set(data) {
  allResults = data.map(i => {
    if (i.Type === 'Participant') {
      return assign({}, i, {
        id: `participant-${i.Participant_ID}`,
        display: i.Participant_Name,
        title: i.Participant_Name,
        keywords: i.Keywords,
        type_display: i.Trip_Name,
        type: 'participant',
        image: <ParticipantPhoto url={i.File_URL} />
      });
    }
    else if (i.Type === 'Trip') {
      return assign({}, i, {
        id: `trip-${i.Trip_ID}`,
        display: i.Trip_Name,
        title: i.Trip_Name,
        keywords: i.Keywords,
        type_display: i.Type_Display,
        type: 'trip',
        image: <TripPhoto url={i.File_URL} />
      });
    }
  });
  allResults.forEach(i => {
    allResultsById[i.id] = i;
  });
  index = makeIndex();
  allResults.forEach(i => {index.add(i); });
  results = query ? index.search(query).map(i => allResultsById[i.ref]) : [];
}


function fetchAll() {
  $.ajax({
    url: getUrl('/api/v1/search'),
    type: 'GET'
  }).done(response =>{
    set(response.data);
    SearchStore.emitChange();
  });
}


const SearchStore = assign(new EventEmitter(), {
  emitChange() {
    this.emit(CHANGE_EVENT);
  },

  addChangeListener(fn) {
    this.addListener(CHANGE_EVENT, fn);
  },

  removeChangeListener(fn) {
    this.removeListener(CHANGE_EVENT, fn);
  },

  getAll() {
    return query ? results : allResults.filter(i => i.type === 'trip');
  },

  getQuery() {
    return query;
  },

  dispatchToken: dispatcher.register(action => {
    switch (action.type) {
      case C.FETCH_ALL:
        fetchAll();
        break;
      case C.FILTER:
        query = action.filter;
        results = query ? index.search(query).map(i => allResultsById[i.ref]) : [];
        SearchStore.emitChange();
        break;
    }

    return true;
  })
});


export default SearchStore;
