import URL from 'url';
import assign from 'object-assign';


let token = null;
let urlParams = {};


export function setupUrl(params) {
  urlParams = params;
}


export function getUrl(url) {
  let parsed = assign(URL.parse(url, true), urlParams);
  delete parsed.search;

  if (token) {
    parsed.query.token = token;
  }
  
  return URL.format(parsed);
}


export function setToken(t) {
  token = t;
}


export function getToken(t) {
  return token;
}


export function formatDateRange(startDate, endDate) {
  let dateString;

  if (startDate.year() === endDate.year()) {
    if (startDate.month() === endDate.month()) {
      if (startDate.date() === endDate.date()) {
        dateString = startDate.format('MMMM D, YYYY');
      }
      else {
        dateString = startDate.format('MMMM D - ') + endDate.format('D, YYYY');
      }
    }
    else {
      dateString = startDate.format('MMMM D - ') + endDate.format('MMMM D, YYYY');
    }
  }
  else {
    dateString = startDate.format('MMMM D, YYYY - ') + endDate.format('MMMM D, YYYY');
  }

  return dateString;
}