import dispatcher from '../dispatcher';
import $ from 'jquery';
import {TripConstants as C} from '../constants';
import {getUrl} from '../util';


export default {
  fetch(id) {
    return $.get(getUrl(`/api/v1/trips/${id}`)).done(trip => {
      dispatcher.dispatch({
        type: C.UPDATE,
        trip: trip
      });
    });
  }
};