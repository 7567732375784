import React from 'react';
import cx from 'classnames';
import $ from 'jquery';
import {getUrl, formatDateRange} from '../util';
import {hashHistory} from 'react-router';
import moment from 'moment';
import TripStore from '../stores/TripStore';
import TripActions from '../actions/TripActions';
import ParticipantStore from '../stores/ParticipantStore';
import ParticipantActions from '../actions/ParticipantActions';
let {Component, PropTypes} = React;

// Components
import ParticipantPhoto from './ParticipantPhoto';
import TripPhoto from './TripPhoto';
import Progress from './Progress';


class Participant extends Component {
  static propTypes = {
    participant: PropTypes.object.isRequired
  }

  render() {
    let participant = this.props.participant;

    return (
      <div className="participant" onClick={this._onClick}>
        <ParticipantPhoto url={this.props.participant.File_URL} />
        <div className="participant-name">
          {participant.Nickname || participant.First_Name} {participant.Last_Name}
        </div>
      </div>
    );
  }

  _onClick = (event) => {
    let participant = this.props.participant;
    let url = `/trips/${participant.Pledge_Campaign_ID}/participants/${participant.Pledge_ID}`;
    hashHistory.push(url);
  }
}


class Photo extends Component {
  static propTypes = {
    trip: PropTypes.object.isRequired
  }

  render() {
    let slideStyle = {
      width: 461,
      // height: 307,
      maxWidth: '100%',
      paddingBottom: '66.666667%',
      position: 'absolute',
      visibility: 'visible',
      zIndex: 20
    };
    let carouselStyle = {
      width: 461,
      maxWidth: '100%',
      paddingBottom: '66.666667%'
    };

    return (
      <div id="carousel-container">
        <div id="carousel" className="carousel" style={carouselStyle}>
          <ul className="slides">
            <li id="slide0" className="slide image" style={slideStyle}>
              <TripPhoto url={this.props.trip.File_URL} />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}


class TripContact extends Component {
  static propTypes = {
    trip: PropTypes.object.isRequired
  }

  render() {
    let trip = this.props.trip;
    let firstName = trip.Primary_Contact_Nickname || trip.Primary_Contact_First_Name;
    let lastName = trip.Primary_Contact_Last_Name;
    let email = trip.Primary_Contact_Email_Address;

    return firstName && lastName && email ? (
      <span>
        <a href={`mailto:${email}`}>{firstName} {lastName}</a>
      </span>
    ) : null;
  }
}


class TripLeader extends Component {
  static propTypes = {
    participant: PropTypes.object.isRequired
  }

  render() {
    let participant = this.props.participant;
    let firstName = participant.Nickname || participant.First_Name;
    let lastName = participant.Last_Name;

    return (
      <span>{firstName} {lastName} (Trip Leader)</span>
    );
  }
}


class Detail extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired
  }

  render() {
    return (
      <li>
        <h3>{this.props.label}</h3>
        <p>
          {this.props.children}
        </p>
      </li>
    );
  }
}


class MinistryLink extends Component {
  static propTypes = {
    trip: PropTypes.object.isRequired
  }

  render() {
    let trip = this.props.trip;
    let name = trip.Portal_Ministry_Name;
    let link = trip.Portal_Ministry_Link;

    if (name && link) {
      return <a href={link} target="_blank">{name}</a>;
    }
    else {
      return <span>{name || 'N/A'}</span>;
    }
  }
}


export default class Trip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trip: TripStore.get(this.props.params.tripId),
      participants: ParticipantStore.getTrip(this.props.params.tripId),
      leaders: ParticipantStore.getTrip(this.props.params.tripId).filter(i => i.Trip_Leader),
      notFound: false
    };
  }

  componentDidMount() {
    TripStore.addChangeListener(this._onChange);
    ParticipantStore.addChangeListener(this._onChange);
    ParticipantActions.fetchTrip(this.props.params.tripId);

    TripActions.fetch(this.props.params.tripId).fail(() => {
      this.setState({
        notFound: true
      });
    });
  }

  componentWillUnmount() {
    TripStore.removeChangeListener(this._onChange);
    ParticipantStore.removeChangeListener(this._onChange);
  }

  render() {
    let inner;

    if (this.state.notFound) {
      inner = this._getNotFound();
    }
    else if (!this.state.trip) {
      inner = this._getLoading();
    }
    else {
      inner = this._getInner();
    }

    return (
      <div className="page trip">
        {inner}
      </div>
    );
  }

  _getLoading() {
    return <h2>Loading...</h2>;
  }

  _getNotFound() {
    return (
      <div>
        <h2>Mission Trip Not Found</h2>
        <p>
          Oops! We couldn't find the requested mission trip. :(
        </p>
      </div>
    );
  }

  _getInner() {
    let trip = this.state.trip;
    let goal = Number(trip.Campaign_Goal) || 0;
    let raised = Number(trip.Amount_Raised) || 0;
    let startDate = moment(trip.Event_Start_Date);
    let endDate = moment(trip.Event_End_Date);
    let dateString = formatDateRange(startDate, endDate);
    let html = {
      __html: trip.Description
    };
    let registrationEnd = moment(trip.Registration_End);
    let filter = encodeURIComponent(`campaign:${trip.Pledge_Campaign_ID}`);
    const campaign = `Campaign #${trip.Pledge_Campaign_ID}`;
    let giveLink = `https://my.seacoast.org/portal/onlinegiving?online_giving=/donate/form/731&fund=${encodeURIComponent(campaign)}&lf=1&guest=1`;
    // let giveLink = `https://my.seacoast.org/portal/mission_trip_giving.aspx?filter=${filter}`;
    let applyLink = (
      trip.Event_External_Registration_URL ||
      `https://my.seacoast.org/portal/mission_trip_registration.aspx?missionTripId=${trip.Pledge_Campaign_ID}`
    );
    let registrationActive = (function() {
      if (trip.Event_Registration_Active === false) {
        return false;
      }
      let now = moment();
      let start = trip.Event_Registration_Start ? moment(trip.Event_Registration_Start) : now;
      let end = trip.Event_Registration_End ? moment(trip.Event_Registration_End) : now;
      return (start.isBefore(now) || start.isSame(now)) && (end.isAfter(now) || end.isSame(now));
    })();

    return (
      <article className="mission">
        <header>
          <h1>{trip.Nickname || trip.Campaign_Name}</h1>
          <div className="dates">{dateString}</div>
        </header>
        <div className="details">
          <Photo trip={trip} />
          <div style={{textAlign: 'center'}}>
            <Progress percentage={raised/goal} />
            <div className="funds-notice">
              Any funds over the individual trip cost will be used to support the Global Missions Ministry.
            </div>
          </div>
          <h3>Details</h3>
          <ul>
            <Detail label="Contact">
              <TripContact trip={trip} />
              {this.state.leaders.map(i => <TripLeader participant={i} key={i.Pledge_ID} />)}
            </Detail>
            <Detail label="Ministry">
              <MinistryLink trip={trip} />
            </Detail>
            <Detail label="Capacity">
              <span>{trip.Maximum_Registrants}</span>
            </Detail>
            <Detail label="Registration Deadline">
              <span>{registrationEnd.format('MMMM D, YYYY')}</span>
            </Detail>
            <Detail label="Cost">
              <span>${trip.Fundraising_Goal}</span>
            </Detail>
            <li className="action-steps">
              <ul>
                <li className={cx({hide: !trip.Allow_Online_Pledge})}>
                  <a href={giveLink} className="support">Support This Trip</a>
                </li>&nbsp;
                <li className={cx({hide: !registrationActive})}>
                  <a href={applyLink} className="apply">Apply for This Trip</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="description" dangerouslySetInnerHTML={html} />
        <div style={{clear: 'both'}} />
        <hr />
        <h3>The Team</h3>
        <div className="participants">
          {this.state.participants.map(i =>
            <Participant participant={i} key={i.Pledge_ID} />
          )}
        </div>
      </article>
    );
  }

  _onChange = () => {
    this.setState({
      trip: TripStore.get(this.props.params.tripId),
      participants: ParticipantStore.getTrip(this.props.params.tripId),
      leaders: ParticipantStore.getTrip(this.props.params.tripId).filter(i => i.Trip_Leader)
    });
  }
}
