import React from 'react';
let {Component, PropTypes} = React;


export default class NotFound extends Component {
  render() {
    return (
      <div className="page not-found">
        <h2>Not Found</h2>
      </div>
    );
  }
}