import React from 'react';
import {Promise} from 'es6-promise';
import {getUrl} from '../util';
let {PropTypes, Component} = React;


let jsPromise = null;


function getTinyMCE() {
  if (!jsPromise) {
    jsPromise = new Promise((resolve, reject) => {
      if (!window.tinymce) {
        let scriptEl = document.createElement('script');
        scriptEl.src = getUrl('/assets/tinymce/tinymce.min.js');
        scriptEl.addEventListener('load', () => {
          resolve();
        }, false);
        document.body.appendChild(scriptEl);
      }
      else {
        resolve();
      }
    });
  }
  return jsPromise;
}


export default class TinyMCE extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func
  }

  getValue() {
    return this.editor.getContent();
  }

  componentDidMount() {
    let self = this;
    getTinyMCE().then(() => {
      tinymce.init({
        target: this.refs.texteditor,
        plugins: 'link,paste',
        // images_upload_credentials: true,
        // images_upload_url: '/api/v1/upload_tinymce_image',
        // paste_data_images: true,
        menubar: false,
        toolbar: 'bold italic underline strikethrough | link',
        height: 250,
        setup(editor) {
          self.editor = editor;
          let changeHandler = event => {
            if (self.props.onChange) {
              self.props.onChange(editor.getContent());
            }
          }
          editor.on('change', changeHandler);
          editor.on('keyup', changeHandler);
          editor.on('paste', changeHandler);
          editor.on('cut', changeHandler);
        }
      });
    });
  }

  setValue(value) {
    console.log('set value', value);
    this.editor.setContent(value);
    // this.editor.focus();
  }

  render() {
    return (
      <textarea ref="texteditor" defaultValue={this.props.defaultValue || ''} />
    );
  }
}