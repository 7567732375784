import React from 'react';
import cx from 'classnames';
let {Component, PropTypes} = React;

// Components
import TinyMCE from './TinyMCE';
import {Loading} from 'bp/ui';


export default class InlineEditor extends Component {
  static propTypes = {
    className: PropTypes.any,
    value: PropTypes.string,
    onChange: PropTypes.func,
    loading: PropTypes.bool
  }

  static defaultProps = {
    loading: false
  }

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      lastSavedValue: props.value,
      value: props.value
    };
  }

  render() {
    let inner;

    if (this.state.editing || this.props.loading) {
      inner = this._getEditor();
    }
    else {
      inner = this._getContent();
    }

    return (
      <div className="inline-editor">
        {inner}
      </div>
    );
  }

  _getContent() {
    let html = {
      __html: this.state.value || ''
    };

    return (
      <div>
        <div className={this.props.className} dangerouslySetInnerHTML={html} />
        <a className="button edit" href="javascript:void(0)" onClick={this._onEdit}>Edit</a>
      </div>
    );
  }

  _getEditor() {
    return (
      <div className="editor">
        <Loading show={this.props.loading} />
        <div className="input-wrapper">
          <TinyMCE ref="input" defaultValue={this.state.value} onChange={this._onChange} />
        </div>
        <a className="button edit" href="javascript:void(0)" onClick={this._onSave}>Save</a>
        <a className="button edit" href="javascript:void(0)" onClick={this._onCancel}>Cancel</a>
      </div>
    );
  }

  _onChange = (value) => {
    this.setState({
      value: value
    });
  }

  _onEdit = (event) => {
    event.preventDefault();
    this.setState({
      editing: true
    });
  }

  _onSave = (event) => {
    event.preventDefault();
    this.setState({
      editing: false,
      lastSavedValue: this.state.value
    });
    if (this.props.onChange) {
      this.props.onChange(this.state.value);
    }
  }

  _onCancel = (event) => {
    event.preventDefault();
    this.setState({
      editing: false,
      value: this.state.lastSavedValue
    });
  }
}