import dispatcher from '../dispatcher';
import $ from 'jquery';
import {ParticipantConstants as C} from '../constants';
import {getUrl} from '../util';


export default {
  fetch(tripId, id) {
    return $.get(getUrl(`/api/v1/trips/${tripId}/participants/${id}`)).done(participant => {
      dispatcher.dispatch({
        type: C.UPDATE,
        participant: participant
      });
    });
  },

  fetchTrip(tripId) {
    return $.get(getUrl(`/api/v1/trips/${tripId}/participants`)).done(response => {
      dispatcher.dispatch({
        type: C.UPDATE_TRIP,
        tripId: tripId,
        participants: response.data
      });
    });
  },

  update(tripId, id, data) {
    dispatcher.dispatch({
      type: C.SAVE,
      tripId: tripId,
      id: id,
      data: data
    });
  }
};