import React from 'react';
import ReactDOM from 'react-dom';
import {validators} from 'bp/validation';
import {getUrl} from '../util';
let {Component, PropTypes} = React;

// Components
import {AjaxForm, TextInput, Field} from 'bp/form';
import TinyMCEInput from './TinyMCEInput';


export default class ContactDonorModal extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    close: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    
    this.state = {
      sent: false
    };
  }

  render() {
    let inner;

    if (this.state.sent) {
      inner = this._getSent();
    }
    else {
      inner = this._getContactForm();
    }

    return (
      <div className="contact-donor-modal" onClick={this._onBackdropClick}>
        <div className="contact-donor-modal-window" onClick={this._onWindowClick}>
          <div className="contact-donor-modal-window-inner">
            {inner}
          </div>
        </div>
      </div>
    );
  }

  _getSent() {
    return (
      <div>
        <h2>Message Sent</h2>
        <p>
          Your message has been sent. Thank you!
        </p>
        <p>
          <a href="javascript:void(0)" onClick={this._onBackdropClick}>Click here to close this window.</a>
        </p>
      </div>
    );
  }

  _getContactForm() {
    let url = getUrl(`/api/v1/donors/${this.props.id}/sendmail`);
    return (
      <AjaxForm post={url} onSuccess={this._onSuccess}>
        <Field name="subject" validators={[validators.required()]} label="Subject">
          <TextInput />
        </Field>
        <Field name="body" validators={[validators.required()]} label="Message">
          <TinyMCEInput />
        </Field>
        <div className="submit">
          <button type="submit">Send</button>
          <button onClick={this._onBackdropClick}>Cancel</button>
        </div>
      </AjaxForm>
    );
  }

  _onWindowClick = (event) => {
    event.stopPropagation();
  }

  _onBackdropClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.close();
  }

  _onSuccess = () => {
    this.setState({
      sent: true
    });
  }

  static open = (id) => {
    let el = document.createElement('div');
    let close = () => {
      ReactDOM.unmountComponentAtNode(el);
      el.remove();
    }
    document.body.appendChild(el);
    ReactDOM.render(<ContactDonorModal id={id} close={close} />, el);
  }
}