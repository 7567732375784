import React from 'react';
import {Table, Header, Body, Row, Cell} from 'bp/table';
import {formatDateRange, getUrl} from '../util';
import moment from 'moment';
import TripStore from '../stores/TripStore';
import TripActions from '../actions/TripActions';
import ParticipantStore from '../stores/ParticipantStore';
import ParticipantActions from '../actions/ParticipantActions';
import $ from 'jquery';
import cx from 'classnames';
import {hashHistory} from 'react-router';
let {Component, PropTypes} = React;

// Components
import Progress from './Progress';
import ParticipantPhoto from './ParticipantPhoto';
import ParticipantPhotoEditor from './ParticipantPhotoEditor';
import ContactDonorModal from './ContactDonorModal';
import InlineEditor from './InlineEditor';
import {Loading} from 'bp/ui';


class BackButton extends Component {
  static propTypes = {
    tripId: PropTypes.string.isRequired
  }

  render() {
    return (
      <div className="back-to-trip">
        <a className="button" href="javascript:;" onClick={this._onClick}>Back to Trip</a>
      </div>
    );
  }

  _onClick = (event) => {
    event.preventDefault();
    hashHistory.push(`/trips/${this.props.tripId}`);
  }
}


class Donor extends Component {
  static propTypes = {
    donor: PropTypes.object
  }

  render() {
    let d = this.props.donor;
    let btnClasses = cx('button', {
      hide: d.Anonymous
    });

    return (
      <Row>
        <Cell>{d.Nickname || d.First_Name} {d.Last_Name}</Cell>
        <Cell>${d.Amount}</Cell>
        <Cell className="desktop-only">{d.Notes}</Cell>
        <Cell className="right"><a href="javascript:void(0)" onClick={this._onContact} className={btnClasses}>Say Thanks</a></Cell>
      </Row>
    );
  }

  _onContact = (event) => {
    event.preventDefault();
    ContactDonorModal.open(this.props.donor.Donation_Distribution_ID);
  }
}


class DonorsTable extends Component {
  static propTypes = {
    donors: PropTypes.arrayOf(PropTypes.object).isRequired
  }

  render() {
    let noDonors = this.props.donors.length ? null :
      <Row>
        <Cell>You have no donors (yet)</Cell>
        <Cell></Cell>
        <Cell className="desktop-only"></Cell>
        <Cell></Cell>
      </Row>;

    return (
      <div className="participant-donors">
        <Table>
          <Header>
            <Row>
              <Cell>Donor Name</Cell>
              <Cell>Amount</Cell>
              <Cell className="desktop-only">Notes</Cell>
              <Cell>&nbsp;</Cell>
            </Row>
          </Header>
          <Body>
            {noDonors}
            {this.props.donors.map(i =>
              <Donor key={i.Donation_Distribution_ID} donor={i} />
            )}
          </Body>
        </Table>
      </div>
    );
  }
}


class ShareableLink extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired
  }
  
  constructor(props) {
    super(props);
    
    this.state = {
      copied: false
    };
  }

  render() {
    let shareLink = location.href;
    let classes = {
      hide: !this.state.copied
    };

    return (
      <div className={cx('shareable-link', {hide: !this.props.show})}>
        <input type="text" readOnly onClick={this._onShareLinkClick} value={shareLink} />
        <div className={cx('copied-container', classes)}>
          <div className="copied">Copied to clipboard</div>
        </div>
      </div>
    );
  }

  _onShareLinkClick = (event) => {
    event.target.select();

    if (document.execCommand) {
      document.execCommand('copy');
      this.setState({
        copied: true
      }, () => {
        setTimeout(() => {
          this.setState({
            copied: false
          });
        }, 6000);
      });
    }
  }
}


export default class Participant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trip: TripStore.get(this.props.params.tripId),
      participant: ParticipantStore.get(this.props.params.participantId),
      donors: [],
      owner: false,
      loading: false,
      notFound: false
    };
  }

  componentDidMount() {
    let tripId = this.props.params.tripId;
    let id = this.props.params.participantId;

    TripStore.addChangeListener(this._onChange);
    ParticipantStore.addChangeListener(this._onChange);
    TripActions.fetch(tripId);

    ParticipantActions.fetch(tripId, id).fail(() => {
      this.setState({
        notFound: true,
        loading: false
      });
    });

    $.get(getUrl(`/api/v1/trips/${tripId}/participants/${id}/donors`)).done(response => {
      this.setState({
        donors: response.data,
        owner: true
      });
    });
  }

  componentWillUnmount() {
    TripStore.removeChangeListener(this._onChange);
    ParticipantStore.removeChangeListener(this._onChange);
  }

  render() {
    let inner;

    if (this.state.notFound) {
      inner = this._getNotFound();
    }
    else if (!this.state.trip || !this.state.participant) {
      inner = this._getLoading();
    }
    else if (!this.state.owner) {
      inner = this._getUnowned();
    }
    else {
      inner = this._getOwned();
    }

    return (
      <div className="page participant">
        {inner}
      </div>
    );
  }

  _getNotFound() {
    return (
      <div>
        <h2>Mission Trip Participant Not Found</h2>
        <p>
          Oh dear. We couldn't locate the mission trip participant that you requested. :(
        </p>
        <BackButton tripId={this.props.params.tripId} />
      </div>
    );
  }

  _getLoading() {
    return <Loading show={true} />;
  }

  _getUnowned() {
    let trip = this.state.trip;
    let participant = this.state.participant;
    let name = participant.Nickname || participant.First_Name || '';
    let goal = Number(participant.Total_Pledge) || 0;
    let raised = Number(participant.Amount_Raised) || 0;
    let startDate = moment(trip.Event_Start_Date);
    let endDate = moment(trip.Event_End_Date);
    let dateString = formatDateRange(startDate, endDate);
    let html = {
      __html: participant.Biography
    };
    let filter = encodeURIComponent(`campaign:${trip.Pledge_Campaign_ID}|pledge:${participant.Pledge_ID}`);
    const campaign = `Campaign #${trip.Pledge_Campaign_ID}`;
    const subfund = `${trip.Campaign_Name}, ${participant.Display_Name} (${participant.Pledge_ID})`;
    let giveLink = `https://my.seacoast.org/portal/onlinegiving?online_giving=/donate/form/731&fund=${encodeURIComponent(campaign)}&sub_fund=${encodeURIComponent(subfund)}&lf=1&guest=1`;
    console.log('giveLink', giveLink);
    // let giveLink = `https://my.seacoast.org/portal/mission_trip_giving.aspx?filter=${filter}`;

    return (
      <div>
        <h2 className="page-title">Mission Trip: {trip.Nickname || trip.Campaign_Name}</h2>
        <h3 className="date-range">{dateString}</h3>
        <div className="participant-meta">
          <div className="participant-info">
            <ParticipantPhoto url={participant.File_URL} />
            <div className="participant-name" style={{marginTop: 5}}>
              {participant.Nickname || participant.First_Name} {participant.Last_Name}
            </div>
          </div>
          <div className="amount-raised">
            <div className="amount-raised-text">${raised.toFixed(0)} of ${goal.toFixed(0)} raised</div>
            <Progress percentage={raised/goal} />
            <div className="funds-notice">
              Any funds over the individual trip cost will be used to support the Global Missions Ministry.
            </div>
            <div className="give-button">
              <a href={giveLink} className={cx("button", {hide: !trip.Allow_Online_Pledge})}>Support {name} {participant.Last_Name || ''}</a>
            </div>
          </div>
        </div>
        <div className="participant-biography" dangerouslySetInnerHTML={html} />
        <BackButton tripId={this.props.params.tripId} />
      </div>
    );
  }

  _getOwned() {
    let trip = this.state.trip;
    let participant = this.state.participant;
    let name = participant.Nickname || participant.First_Name;
    let goal = Number(participant.Total_Pledge) || 0;
    let raised = Number(participant.Amount_Raised) || 0;
    let startDate = moment(trip.Event_Start_Date);
    let endDate = moment(trip.Event_End_Date);
    let dateString = formatDateRange(startDate, endDate);
    let html = {
      __html: participant.Biography
    };
    let filter = encodeURIComponent(`campaign:${trip.Pledge_Campaign_ID}|pledge:${participant.Pledge_ID}`);
    const campaign = `Campaign #${trip.Pledge_Campaign_ID}`;
    const subfund = `${trip.Campaign_Name}, ${participant.Display_Name} (${participant.Pledge_ID})`;
    let giveLink = `https://my.seacoast.org/portal/onlinegiving?online_giving=/donate/form/731&fund=${encodeURIComponent(campaign)}&sub_fund=${encodeURIComponent(subfund)}&lf=1&guest=1`;
    console.log('giveLink', giveLink);
    // let giveLink = `https://my.seacoast.org/portal/mission_trip_giving.aspx?filter=${filter}`;

    return (
      <div>
        <h2 className="page-title">My Mission Trip: {trip.Nickname || trip.Campaign_Name}</h2>
        <h3 className="date-range">{dateString}</h3>
        <div className="participant-meta">
          <div className="participant-info">
            <ParticipantPhotoEditor participant={participant} />
            <div className="participant-name">
              {participant.Nickname || participant.First_Name} {participant.Last_Name}
            </div>
          </div>
          <div className="amount-raised">
            <div className="amount-raised-text">${raised.toFixed(0)} of ${goal.toFixed(0)} raised</div>
            <Progress percentage={raised/goal} />
            <div className="funds-notice">
              Any funds over the individual trip cost will be used to support the Global Missions Ministry.
            </div>
            <div className="give-button">
              <a href={giveLink} className={cx("button", {hide: !trip.Allow_Online_Pledge})}>Give to {name}'s Trip</a>
              <a href="https://www.facebook.com/seacoast" className="button">
                <i className="fa fa-fw fa-facebook" /> Facebook
              </a>
              <a href="https://twitter.com/SeacoastChurch" className="button">
                <i className="fa fa-fw fa-twitter" /> Twitter
              </a>
            </div>
          </div>
        </div>
        <InlineEditor className="participant-biography" value={participant.Biography}
            onChange={this._onBiographyChange} />
        <hr />
        <h3>My Donors</h3>
        <DonorsTable donors={this.state.donors} />
        <hr />
        <h3>Shareable Link</h3>
        <ShareableLink show={this.state.owner} />
        <hr />
        <BackButton tripId={this.props.params.tripId} />
      </div>
    );
  }

  _onChange = () => {
    this.setState({
      trip: TripStore.get(this.props.params.tripId),
      participant: ParticipantStore.get(this.props.params.participantId),
      loading: false
    });
  }

  _onBiographyChange = (biography) => {
    let participant = this.state.participant;
    this.setState({
      loading: true
    });
    ParticipantActions.update(participant.Pledge_Campaign_ID, participant.Pledge_ID, {
      Biography: biography
    });
  }
}
