import React from 'react';
import {TextInput} from 'bp/form';
import TinyMCE from './TinyMCE';


export default class TinyMCEInput extends TextInput {
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      // this.refs.tinymce.setValue(this.props.value);
    }   
  }

  render() {
    return (
      <TinyMCE ref="tinymce" onChange={this.props.onChange} />
    );
  }
}